import { Injectable } from '@angular/core';
import { Movimiento } from '../interface/interface';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ConfigServices } from './config.services';
import { SocketService } from '../socket/socket.services';
import { map } from 'rxjs/operators/map';

@Injectable()

export class MovimientosServices {

  private url: string = '';
  fil: any = [];
  public movimiento: Movimiento[] = [];

  constructor(private http: HttpClient,
    private _configServices: ConfigServices,
    private _socketService: SocketService) {
    console.log("Movimientos Services");
    this.url = this._configServices.url;
  }

  getMovimientos(contrato: string, desde: number, limite: number, fecsta: string, fecend: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('desde', `${desde}`)
        .set('limite', `${limite}`)
        .set('fecsta', `${fecsta}`)
        .set('fecend', `${fecend}`)
        .set('contrato', `${contrato}`);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/movimientoscontrato`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
             reject(msg);
          }
        );
    });
  }

  getFilMovimientos(contrato: string, desde: number, limite: number, fecsta: string, fecend: string) {

    const params = new HttpParams()
      .set('desde', `${desde}`)
      .set('limite', `${limite}`)
      .set('fecsta', `${fecsta}`)
      .set('fecend', `${fecend}`)
      .set('contrato', `${contrato}`);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': localStorage.getItem('tokenUser')
    });

    return this.http.post(`${this.url}/movimientos`, params, { headers })
      .pipe(
        map(this.crearArreglo)
      );
  }

  private crearArreglo(dataObj: object) {
    const data: any = [];
    if (dataObj === null) { return []; } else {
      Object.keys(dataObj).forEach(key => {
        const dat: [] = dataObj[key];
        data.push(dat);
      });
      return data;
    }
  }

  getMovimiento(idx: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('_id', `${idx}`);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/movimiento`, params, { headers })
        .toPromise()
        .then(
          data => {
            resolve(data);
          },
          msg => {
            reject(msg);
          });
    });
  }

  setMovimiento(idx: string, estado: number) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('_id', `${idx}`).set('confirmacion', `${estado}`);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      const url = `${this.url}/movimiento/${idx}`;
      this.http.put(url, params, { headers })
        .toPromise()
        .then(
          data => {
            let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
            this._socketService.upMovimiento(dispositivo.cliente);
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getRegistro(idx: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('_id', `${idx}`);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/confirmacion`, params, { headers })
        .toPromise()
        .then(
          data => {
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          });
    });
  }

  getCorMvo(tipo, desde, limite, contratos) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('desde', `${desde}`)
        .set('limite', `${limite}`)
        .set('contratos', `${JSON.stringify(contratos)}`)
        .set('tipo', `${tipo}`);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/corgenerales`, params, { headers })
        .toPromise()
        .then(
          data => {
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          });
    });
  }

  getCorMvoUn(desde, limite, contrato, fecsta, fecend) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('desde', `${desde}`)
        .set('limite', `${limite}`)
        .set('fecsta', `${fecsta}`)
        .set('fecend', `${fecend}`)
        .set('contrato', `${contrato}`);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/detalleparticion`, params, { headers })
        .toPromise()
        .then(
          data => {
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          });
    });
  }

  setUnidadStatus(unidad, tipo) {
    return new Promise((resolve, reject) => {

      let seleccion = unidad.split('|');
      
      let particion = seleccion[2];
      let contrato = seleccion[0];

      const params = new HttpParams()
      .set('contrato', `${contrato}`)
      .set('particion', `${particion}`)
      .set('tipo', `${tipo}`);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });
      
      this.http.put(`${this.url}/upunidadstatus`, params, { headers })
        .toPromise()
        .then(
          data => {
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          });
    });
  }
}
