import { Component, Output, EventEmitter } from '@angular/core';
import { Movimiento } from '../../../interface/interface';
import { MovimientosServices } from '../../../services/movimientos.services';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.css']
})
export class ConfirmacionComponent {

  movimiento: Movimiento[] = [];

  @Output() retornoListMovimiento = new EventEmitter<string>();

  constructor(private _movimientosServices: MovimientosServices) {

  }

  cargarMovimiento(idx1: string) {
    this._movimientosServices.getRegistro(idx1)
      .then(
        data => {
          this.movimiento = data['movimiento'][0];
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }

  retornoMov() {
    this.retornoListMovimiento.emit();
  }
}
