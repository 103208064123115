import { Injectable } from '@angular/core';
import { Dispositivo } from '../interface/interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigServices } from './config.services';

@Injectable()
export class DispositivosServices {

  private url: string = '';
  public contrato: string = '';
  public dispositivos: Dispositivo[] = [];

  constructor(private http: HttpClient, private _configService: ConfigServices) {
    console.log("Dispositivos Services");
    this.url = this._configService.url;
  }

  getDispositivos() {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('desde', "0").set('limite', "5");
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/dispositivos`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getDispositivo(contrato: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('contrato', `${contrato}`);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/dispositivo`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }
}
