import { Component, Output, EventEmitter } from '@angular/core';
import { PqrServices } from '../../../../services/pqr.services';

@Component({
  selector: 'app-verpqr',
  templateUrl: './verpqr.component.html',
  styleUrls: ['./verpqr.component.css']
})

export class VerPqrComponent {

  pqrs: any[] = [];
  dispositivos: any[] = [];

  @Output() cargarListPqr: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _pqrServices: PqrServices) {

  }

  verPqr(idx1: string) {
    this._pqrServices.getPqr(idx1)
      .then((data) => {
        this.pqrs = data['pqr'][0];
      })
      .catch((error) => {
        console.log(error);
      });
  }

  retornoPqr() {
    this.cargarListPqr.emit();
  }
}
