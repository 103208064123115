import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigServices } from './config.services';
import 'rxjs/Rx';

@Injectable()

export class UsuariosServices {
  url: string = "";

  constructor(private http: HttpClient, private _configServices: ConfigServices) {
    console.log("Usuarios Services");
    this.url = this._configServices.url;
  }

  upUsuarioPin(pin: string, id: string) {
    const url = `${this.url}/upusuario/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': localStorage.getItem('tokenUser')
    });

    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('pin', pin);
      this.http.put(url, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getUsuarios() {

    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('desde', "0")
        .set('limite', "5");
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/usuarios`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getUsuario(idx1: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('id', idx1);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.get(`${this.url}/usuario`, { headers, params })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  crearUsuario(data) {
    let current_datetime = new Date()
    let formatted_date = `${current_datetime.getFullYear()}-${(current_datetime.getMonth() + 1)}-${current_datetime.getDate()}`;

    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('nombre', data.nombre)
        .set('email', data.email)
        .set('telefono', data.telefono)
        .set('rol', data.permiso)
        .set('perfil', data.perfil)
        .set('fecha_creacion', formatted_date)
        .set('ciudad', data.ciudad);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/usuario`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }
}
