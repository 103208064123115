import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigServices } from './config.services';
import 'rxjs/Rx';

@Injectable()

export class EncargadosServices {

  private url: string = '';

  constructor(private http: HttpClient, private _configService: ConfigServices) {
    console.log("Encargados Services");
    this.url = this._configService.url;
  }

  getEncargados(contrato: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('desde', "0")
        .set('limite', "5")
        .set('contrato', contrato);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/encargados`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getEncargado(idx1: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('id', idx1);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.get(`${this.url}/encargado`, { headers, params })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  crearEncargado(nombre: string, telefono1: string, cdi: string, contrato: string,
    telefono2: string, prioridad: string, descripcion: string) {
    let stUsuario = JSON.parse(localStorage.getItem('currentUser'));
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('nombre', nombre)
        .set('telefono1', telefono1)
        .set('telefono2', telefono2)
        .set('prioridad', prioridad)
        .set('cdi', cdi)
        .set('creado_por', stUsuario.nombre)
        .set('contrato', contrato)
        .set('id_4d', '')
        .set('descripcion', descripcion);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/encargado`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }
}
