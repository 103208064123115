import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigServices } from './config.services';
import 'rxjs/Rx';

@Injectable()

export class PqrServices {

  url: string = "";

  constructor(private http: HttpClient, private _configServices: ConfigServices) {
    console.log("Pqr Clientes");
    this.url = this._configServices.url;
  }

  crearPqr(motivo: string, comentarios: string) {
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    return new Promise((resolve, reject) => {

      const params = new HttpParams()
        .set('comentario', comentarios)
        .set('motivo', motivo)
        .set('contrato', dispositivo.contrato);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/pqr`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getPqrs(desde: string, limite: string, contrato: string) {

    if (desde == '') {
      desde = '0';
    }

    if (limite == '') {
      limite = '5';
    }

    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('contrato', contrato)
        .set('desde', desde)
        .set('limite', limite);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/pqrs`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getPqr(idx: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('id', idx);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.get(`${this.url}/pqr`, { headers, params })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }
}
