import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { UsuariosServices } from '../../../../services/usuarios.services';

@Component({
  selector: 'app-crearusuario',
  templateUrl: './crearusuario.component.html',
  styleUrls: ['./crearusuario.component.css']
})

export class CrearusuarioComponent implements OnInit {

  crearUsuario: boolean = true;
  actualizarUsuario: boolean = false;
  contratos: any[] = [];
  dispositivo: any[] = [];
  items: any[] = [];

  formCrearUsuario: FormGroup;
  formActualizarUsuario: FormGroup;
  formStatus: boolean = false;

  marked = false;
  theCheckbox = false;

  @Output() emitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() cargarListUsuarios: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _usuariosServices: UsuariosServices) {
    this.formCrearUsuario = new FormGroup({
      'nombre': new FormControl('', [Validators.required, Validators.minLength(2)]),
      'apellido': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'telefono': new FormControl('', [Validators.required, Validators.minLength(7)]),
      'cedula': new FormControl('', [Validators.required, Validators.minLength(5)]),
      'email': new FormControl('', [Validators.required, Validators.minLength(5)]),
    })
  }

  ngOnInit() {

    /*let usuario = JSON.parse(localStorage.getItem('currentUser'));
    let dat = JSON.parse(localStorage.getItem('usuario_contrato'));
    let contratos = [];
    dat.forEach((element: any, idx: any) => {
      if (element.idusuario == usuario['_id']) {
        contratos[idx] = element;
      }
    })
    localStorage.setItem('permisos_usuarios', JSON.stringify(contratos));
    this.contratos = JSON.parse(localStorage.getItem('permisos_usuarios'));;*/
  }


  usrLugares() {
    if (this.formCrearUsuario.status == 'INVALID') {
      this.formStatus = true;
    } else {
      this.crearUsuario = false;
      this.actualizarUsuario = true;
    }
  }

  upPermiso(op, idx: string) {
    let estado = 0;
    if (op.target.checked) {
      estado = 1;
    }
    this.items = [{ id: idx, estado: estado }]
    console.log(this.items);
  }

  gdUsuario() {

    //crearUsuario(nombre: string, apellido: string, cedula: string, telefono: string, email: string, contratos: string)
    /*this._usuariosServices.crearUsuario(
      this.formCrearUsuario.value['nombre'],
      this.formCrearUsuario.value['apellido'],
      this.formCrearUsuario.value['cedula'],
      this.formCrearUsuario.value['telefono'],
      this.formCrearUsuario.value['email'])
      .then((data) => {
        if (data['ok']) {
          this.formStatus = false;
          this.cargarListUsuarios.emit();
        }
      }).catch((error) => {
        console.log(error);
      });*/
  }

  retornoUsr() {
    this.cargarListUsuarios.emit();
    this.formStatus = false;
  }
}
