import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { PagosComponent } from './pagos/pagos.component';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})

export class ConfiguracionComponent {

  adicionarTarjeta: boolean = true;

  @Output() emitEventTarjeta: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(PagosComponent) pagView: PagosComponent;

  constructor() { }

  addCard() {
    this.adicionarTarjeta = false;
    this.pagView.addCard();
  }

  retornoListCards(event: any) {
    this.adicionarTarjeta = true;
  }
}
