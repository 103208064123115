import { Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';
import { User } from '../models/user.models';

@Injectable({
  providedIn: 'root'
})
export class LoginSocket extends Socket {

  constructor() {
    super({ url: "https://app.telesentinel.com:3001/sklogin", options: {} });
  }

  iniciarSession(user: User, token: string, idUser: string) {

    let hoy = new Date();

    this.emit("logInService",
      {
        message: 'Session Iniciada',
        usuario: JSON.stringify(user),
        entrada: true,
        salida: false,
        fecha: `${hoy.getFullYear()}-${hoy.getMonth() + 1}-${hoy.getDate()}`,
        hora: `${hoy.getHours()}:${hoy.getMinutes()}:${hoy.getSeconds()}`
      }, function(res: any) {
        console.log(res);
      }
    );

    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('tokenUser', token);
    localStorage.setItem('idUser', idUser);
  }

  cerrarSession(user: User) {
    let hoy = new Date();
    //emite la salida del usuario de la aplicacion
    this.emit("logOutService",
      {
        message: 'Session Finalizada',
        usuario: JSON.stringify(user),
        entrada: false,
        salida: true,
        fecha: `${hoy.getFullYear()}-${hoy.getMonth() + 1}-${hoy.getDate()}`,
        hora: `${hoy.getHours()}  :${hoy.getMinutes()}:${hoy.getSeconds()}`
      }, function(res: any) {
        console.log(res)
      }
    );

    this.emit("disconnect");

    localStorage.setItem('idUser', '');
    localStorage.setItem('usuarios', '');
    localStorage.setItem('usuario_contrato', '');
    localStorage.setItem('tokenUser', '');
    localStorage.setItem('currentUser', '');
    localStorage.setItem('dispositivo', '');
    localStorage.setItem('dispositivos', '');
  }
}
