import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigServices } from './config.services';
import 'rxjs/Rx';

@Injectable()

export class TarjetaServices {

  url: string = "";
  ipAddress: any;

  constructor(private http: HttpClient, private _configServices: ConfigServices) {
    console.log("Tarjetas Clientes");
    this.url = this._configServices.url;
    this.direccionIp();
  }

  direccionIp() {
    this.http.get<{ ip: string }>('https://jsonip.com').subscribe(data => { this.ipAddress = data.ip; });
  }

  crearTarjeta(numero_tarjeta: string, mes: string, anio: string, nombre_tarjeta: string, cvc: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('numero_tarjeta', numero_tarjeta)
        .set('nombre_en_tarjeta', nombre_tarjeta)
        .set('mes', mes)
        .set('anio', anio)
        .set('cvc', cvc)
        .set('direccion_ip_cliente', this.ipAddress);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/creartoken`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  generarPagorecurrente(
    identificador: string,
    total_con_iva: string,
    numero_cuotas: string,
    descripcion: string,
    facturas: string,
    contrato: string) {

    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('identificador', identificador)
        .set('total_con_iva', total_con_iva)
        .set('numero_cuotas', numero_cuotas)
        .set('descripcion', descripcion)
        .set('facturas', facturas)
        .set('id_pago', `${contrato}`)
        .set('direccionip', this.ipAddress);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/pagosrecurrentes`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getTarjetas(desde: string, limite: string) {

    if (desde == '') {
      desde = '0';
    }

    if (limite == '') {
      limite = '5';
    }

    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('desde', desde)
        .set('limite', limite);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/tarjetas`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  getTarjeta(idx: string) {

    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('id', idx);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/tarjeta`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  upTarjeta(idx: string, accion: string) {

    const url = `${this.url}/tarjeta/${idx}`;

    return new Promise((resolve, reject) => {

      const params = new HttpParams().set('predeterminada', accion);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.put(url, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  delTarjeta(idx: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('id', idx);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.delete(`${this.url}/tarjeta`, { headers, params })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }
}
