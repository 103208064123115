import { Component, OnInit } from '@angular/core';
import { CuentasServices } from '../../../services/cuentas.services';
import { TarjetaServices } from '../../../services/tarjeta.services';
import { SocketService } from '../../../socket/socket.services';
import { ModalServices } from '../../../services/modal.services';

import { FormGroup, FormControl, Validators } from '@angular/forms'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.css']
})
export class CuentaComponent implements OnInit {

  cuentas: any[] = [];
  total: number;
  seleccion: any[] = [];
  existenFacturas: boolean = false;
  valorPagar: number = 0;
  imgpse: string = 'assets/images/pse.png';
  tarjetas: any[] = [];
  formPagar: FormGroup;

  constructor(private modalServices: ModalServices, private _cuentasServices: CuentasServices, private _tarjetaServices: TarjetaServices, private _socketService: SocketService) {
    this.formPagar = new FormGroup({
      'tardesc': new FormControl('', [Validators.required]),
    })
  }

  ngOnInit() {
    let contrato = JSON.parse(localStorage.getItem('dispositivo'));
    this.cargarFacturas(contrato.contrato, '0', '5');
    //this.emitFactura();
    /*this._cuentasServices.getCuentas(contrato.cliente)
      .then((data) => {
        this.cuentas = data['facturas'];
      })
      .catch((error) => console.log(error));*/
    this.total = this.cuentas.reduce((acc, obj) => acc + parseInt(obj.valor), 0);
    this.onPago();
  }

  openModal(id: string) {
    this.modalServices.open(id);
  }

  closeModal(id: string) {
    this.modalServices.close(id);
  }

  onPago() {
    this._socketService.upPag
      .subscribe(
        data => {
          let contrato = JSON.parse(JSON.stringify(data));
          this.cargarFacturas(contrato.contrato, '0', '5');
        }
      );
  }

  /*emitFactura() {
    this._socketService.addFactura
      .subscribe(
        data => {
          //let fact: any = [];
          let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
          /*dispositivo.forEach(function(elemento: any) {
            fact.push(elemento.key);
          });
          let contrato = JSON.parse(JSON.stringify(data));
          if (fact.indexOf(contrato.contrato) == 0) {
          this.cargarFacturas(dispositivo.contrato, '0', '5');
          }
        }
      );
  }*/

  cargarFacturas(contrato: string, desde: string, limite: string) {
    this._cuentasServices.getCuentas(contrato, desde, limite)
      .then((data) => {
        if (data['total'] > 0) {
          this.existenFacturas = true;
          this.cuentas = data['facturas'];
        }
        else {
          this.existenFacturas = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getCuentas(contrato: string) {
    this._cuentasServices.getCuentas(contrato, '0', '5')
      .then((data) => {
        if (data['total'] > 0) {
          this.existenFacturas = true;
          this.cuentas = data['facturas'];
        }
        else {
          this.existenFacturas = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addPago(idx: any, pos: number) {
    let contrato = JSON.parse(localStorage.getItem('dispositivo'));
    this._cuentasServices.getCuenta(contrato.contrato, idx)
      .then((data) => {
        let checkBox = document.getElementById("sel_" + pos) as HTMLInputElement;
        if (checkBox.checked == true) {
          this.seleccion.push(idx);
          this.total = parseInt(data['facturas'][0]['valor']) + this.total;
        } else {
          this.seleccion.splice(idx, 1);
          this.total = this.total - parseInt(data['facturas'][0]['valor']);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  generarTransaccion() {
    if (this.formPagar.status == 'INVALID') {
      Swal.fire('Seleccione la Tarjeta');
    }
    if (this.formPagar.status == 'VALID') {
      if (this.total > 0) {
        Swal.fire({
          title: 'Confirmacion',
          text: "¿Generar Pago?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Pagar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.value) {
            let contrato = JSON.parse(localStorage.getItem('dispositivo'));
            this._tarjetaServices.getTarjeta(this.formPagar.value['tardesc'])
              .then((data) => {
                if (data['ok']) {
                  let strtotal = this.total;
                  let total_con_iva = `${strtotal}`;
                  let descripcion = 'Pago de Servicios Telesentinel Monitoreo de Alarma';
                  this._tarjetaServices.generarPagorecurrente(data['tarjeta'][0].token, total_con_iva, '1', descripcion, JSON.stringify(this.seleccion), contrato.contrato)
                    .then((data) => {
                      if (data['ok']) {
                        Swal.fire(data['message']);
                        this.seleccion = [];
                        this.total = 0;
                        this.closeModal('tarjetas');
                        this.getCuentas(contrato.contrato);
                      } else {
                        Swal.fire(data['message'][0]);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                } else {
                  console.log(data);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
      }
    }
  }

  pagar() {
    if (this.total > 0) {
      Swal.fire({
        title: '¿Via de Cancelacion?  ',
        text: "¿Que medio de Pago desea Usar?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '<i class="fas fa-hand-holding-usd"></i> PSE',
        cancelButtonText: '<i class="fab fa-cc-visa"></i> T. Credito',
      }).then((result) => {
        if (result.value) {
          window.open('https://www.telesentinel.com/pagoonlinev2/web/', '', 'width=600,height=400,left=400,top=200');
        }
        else {
          this.openModal('tarjetas');
          this.valorPagar = this.total;
          this._tarjetaServices.getTarjetas('0', '5')
            .then((data) => {
              if (data['total'] > 0) {
                this.tarjetas = data['tarjetas'];
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
    }
    else {
      Swal.fire('Seleccione la(s) <br> factura(s) a cancelar');
    }
  }
}
