import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { CoorporativoComponent } from './component/coorporativo/coorporativo.component';
import { PerfilComponent } from './component/dashboard/configuracion/perfil/perfil.component';

const APP_ROUTES: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'coorporativo', component: CoorporativoComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard/perfil', component: PerfilComponent },
  { path: '**', pathMatch: 'full', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
