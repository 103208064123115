import { Component, Output, EventEmitter } from '@angular/core';
import { EncargadosServices } from '../../../../services/encargados.services';
import { Usuario } from '../../../../interface/interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-crearencargado',
  templateUrl: './crearencargado.component.html',
  styleUrls: ['./crearencargado.component.css']
})

export class CrearEncargadoComponent {

  usuario: Usuario;
  formCrearEncargado: FormGroup;
  formStatus: boolean = false;

  @Output() cargarListEncargados: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _encargadosServices: EncargadosServices) {
    this.formCrearEncargado = new FormGroup({
      'nombre': new FormControl('', [Validators.required, Validators.minLength(4)]),
      'telefono1': new FormControl('', [Validators.required, Validators.minLength(7)]),
      'telefono2': new FormControl('', [Validators.required, Validators.minLength(7)]),
      'descripcion': new FormControl('', [Validators.required, Validators.minLength(10)]),
      'cdi': new FormControl('', [Validators.required, Validators.minLength(10)]),
      'prioridad': new FormControl('', [Validators.required])
    })
  }

  verEncargado(idx1: string) {
    this._encargadosServices.getEncargado(idx1)
      .then((data) => {
        this.usuario = data['encargado'];
      })
      .catch((error) => console.log(error));
  }

  retornoEnc() {
    this.cargarListEncargados.emit();
  }

  addEncargado() {
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this._encargadosServices.crearEncargado(
      this.formCrearEncargado.value['nombre'],
      this.formCrearEncargado.value['telefono1'],
      this.formCrearEncargado.value['cdi'],
      dispositivo.cliente,
      this.formCrearEncargado.value['telefono2'],
      this.formCrearEncargado.value['prioridad'],
      this.formCrearEncargado.value['descripcion']
    ).then((data) => {
      if (data['ok']) {
        this.formStatus = false;
        this.formCrearEncargado.reset();
        this.cargarListEncargados.emit();
      }
    }).catch((error) => {
      console.log(error);
      this.formStatus = true;
    });
  }
}
