import { Component, Output, EventEmitter } from '@angular/core';
import { PqrServices } from '../../../../services/pqr.services';
import { FormGroup, FormControl, Validators } from '@angular/forms'

@Component({
  selector: 'app-crearpqr',
  templateUrl: './crearpqr.component.html',
  styleUrls: ['./crearpqr.component.css']
})

export class CrearPqrComponent {

  formCrearPqr: FormGroup;
  formStatus: boolean = false;

  @Output() cargarListPqr: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _pqrServices: PqrServices) {
    this.formCrearPqr = new FormGroup({
      'motivo': new FormControl('', [Validators.required]),
      'comentarios': new FormControl('', [Validators.required, Validators.minLength(5)])
    })

  }

  retornoPqr() {
    this.cargarListPqr.emit();
  }

  addPqr() {

    if (this.formCrearPqr.status == 'INVALID') {
      this.formStatus = true;
    }

    if (this.formCrearPqr.status == 'VALID') {
      this._pqrServices.crearPqr(
        this.formCrearPqr.value['motivo'],
        this.formCrearPqr.value['comentarios'])
        .then((data) => {
          if (data['ok']) {
            this.formStatus = false;
            this.cargarListPqr.emit();
            this.formCrearPqr.reset();
          }
        }).catch((error) => {
          console.log(error);
        });
    }
  }
}
