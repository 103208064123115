import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { UsuariosServices } from '../../../services/usuarios.services';
import { VerusuarioComponent } from '../usuario/verusuario/verusuario.component';
import { CrearusuarioComponent } from '../usuario/crearusuario/crearusuario.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  usuarios: any[] = [];
  idx: number = 0;

  listUsuarios: boolean = true;
  verUsuarios: boolean = false;
  crearUsuarios: boolean = false;
  editarUsuarios: boolean = false;
  totalPages: number = 1;

  numShops: boolean = true;

  @ViewChild(VerusuarioComponent) usuarioView: VerusuarioComponent;
  @ViewChild(CrearusuarioComponent) usuarioCrear: CrearusuarioComponent;
  @Output() emitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() usuarioModulo: EventEmitter<any> = new EventEmitter<any>();

  @Input() public page: number = 1;
  @Output() paginaEmitter: EventEmitter<number> = new EventEmitter();

  //private numResults: number = 5;
  btnRetUsu: boolean = true;

  constructor(private _usuariosServices: UsuariosServices) {

  }

  ngOnInit() {
    this._usuariosServices.getUsuarios()
      .then((data) => {
        this.usuarios = data['usuarios'];
        localStorage.setItem('usuarios', JSON.stringify(this.usuarios));
        localStorage.setItem('usuario_contrato', JSON.stringify(data['usuariocontrato']));
      })
      .catch((error) => console.log(error));
  }

  verUsuario(idx: string) {
    this.verUsuarios = true;
    this.listUsuarios = false;
    this.crearUsuarios = false;
    this.editarUsuarios = false;
    this.usuarioView.verUsuario(idx);
    this.emitEventUsuarios(event);
    this.btnRetUsu = false;
  }

  emitEventUsuarios(event: any) {
    this.emitEvent.emit(false);
  }

  retornoListUsuarios() {
    this.listUsuarios = true;
    this.crearUsuarios = false;
    this.verUsuarios = false;
  }

  crearUsuario() {
    this.crearUsuarios = true;
    this.verUsuarios = false;
    this.listUsuarios = false;
    this.editarUsuarios = false;
  }

  getUsuarios(contrato: string) {
    /*this._usuariosServices.getUsuarios(contrato)
      .then((data) => {
        this.usuarios = data['usuarios'];
      })
      .catch((error) => {
        console.log(error)
      });*/
  }

  gdUsuario() {
    this.usuarioCrear.gdUsuario();
  }

  cargarListUsuarios(event: any) {
    this.crearUsuarios = false;
    this.verUsuarios = false;
    this.listUsuarios = true;
    this.editarUsuarios = false;
    //this._usuariosServices.getUsuarios().then((data) => { this.usuarios = data['usuarios']; }).catch((error) => console.log(error));
    this.btnRetUsu = true;
  }

  siguiente() {
    this.page++;
    this.pasarPagina();
  }

  anterior() {
    this.page--;
    this.pasarPagina();
  }

  pasarPagina() {

    let usuarios = JSON.parse(localStorage.getItem('usuarios'));
    console.log(usuarios);
    //this.cargarMovimientos(dispositivo.cliente, desde, limite);
  }

  addUsuario() {
    this.crearUsuario();
    this.btnRetUsu = false;
  }
}
