import { Component, Output, EventEmitter } from '@angular/core';
import { UsuariosServices } from '../../../../services/usuarios.services';
import { DispositivosServices } from '../../../../services/dispositivos.services';
import { UsuarioDispositivosServices } from '../../../../services/usuario_dispositivos.services';

import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-verusuario',
  templateUrl: './verusuario.component.html',
  styleUrls: ['./verusuario.component.css']
})

export class VerusuarioComponent {

  usuario: any[] = [];
  dispositivos: any[] = [];
  formActualizarUsuario: FormGroup;
  formActualizarLugares: FormGroup;

  @Output() cargarListUsuarios: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _usrServices: UsuariosServices,
    private _usrDisServices: UsuarioDispositivosServices) {
    this.formActualizarUsuario = new FormGroup({
      'nombre': new FormControl('', [Validators.required, Validators.minLength(4)]),
      'telefono': new FormControl('', [Validators.required, Validators.minLength(10)]),
      'estado': new FormControl('', [Validators.required])
    })

    this.formActualizarLugares = new FormGroup({
      'nombre': new FormControl('', [Validators.required, Validators.minLength(4)]),
      'estado': new FormControl('', [Validators.required])
    })
  }

  verUsuario(idx1: string) {

    this._usrServices.getUsuario(idx1)
      .then((data) => {
        this.usuario = data['usuario'][0];
        let dat = JSON.parse(localStorage.getItem('usuario_contrato'));
        let contratos = [];
        dat.forEach((element: any, idx: any) => {
          if (element.idusuario == this.usuario['_id']) {
            contratos[idx] = element;
          }
        })
        this.dispositivos = contratos;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  estadoContrato(op, idx) {
    this._usrDisServices.upUsuarioDispositivos(idx, op)
      .then((data) => {
        if (data['ok']) {
          localStorage.setItem('usuario_contrato', '');
          this._usrServices.getUsuarios()
            .then((dat) => {
              localStorage.setItem('usuario_contrato', JSON.stringify(dat['usuariocontrato']));
              this.verUsuario(data['dispositivo']['idusuario']);
              console.log("actualizar");
            })
            .catch((error) => console.log(error));

        } else {
          console.log("No se actualizo");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  upUsuario() {
    this.cargarListUsuarios.emit();
  }

  retornoUsr() {
    this.cargarListUsuarios.emit();
  }
}
