import { Component, Output, EventEmitter } from '@angular/core';
import { Movimiento } from '../../../interface/interface';
import { MovimientosServices } from '../../../services/movimientos.services';

@Component({
  selector: 'app-movimiento',
  templateUrl: './movimiento.component.html',
  styleUrls: ['./movimiento.component.css']
})
export class MovimientoComponent {

  movimiento: Movimiento[] = [];

  @Output() retornoListMovimiento = new EventEmitter<string>();
  @Output() cargarListMovimientos = new EventEmitter<string>();

  constructor(private _movimientosServices: MovimientosServices) {

  }

  cargarMovimiento(idx1: string) {
    this._movimientosServices.getMovimiento(idx1)
      .then(
        data => {
          this.movimiento = data['movimiento'][0];
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }

  confirmarEvento(idx: string, estado: number) {
    this._movimientosServices.setMovimiento(idx, estado)
      .then(
        data => {
          console.log(data);
          this.cargarListMovimientos.emit();
          this.retornoListMovimiento.emit();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }
}
