import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EncargadosComponent } from '../encargados/encargados.component';
import { MovimientosComponent } from '../movimientos/movimientos.component';

import { PqrComponent } from '../pqr/pqr.component';
import { Dispositivo } from '../../../interface/interface';
import { CuentaComponent } from '../cuenta/cuenta.component';
import { UsuariosComponent } from '../usuarios/usuarios.component';

import { ModalServices } from '../../../services/modal.services';
import { SocketService } from '../../../socket/socket.services';
import { PagosServices } from '../../../services/pagos.services';
import { MovimientosServices } from '../../../services/movimientos.services';

import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.css']
})

export class ModulosComponent implements OnInit {

  @Input() dispositivo: Dispositivo;

  @ViewChild(PqrComponent) pqrsView: PqrComponent;
  @ViewChild(CuentaComponent) facturasView: CuentaComponent;
  @ViewChild(UsuariosComponent) usuariosView: UsuariosComponent;
  @ViewChild(EncargadosComponent) encargadosView: EncargadosComponent;
  @ViewChild(MovimientosComponent) movimientosView: MovimientosComponent;

  btnRetMov: boolean = true;
  btnRetUsu: boolean = true;
  btnRetEnc: boolean = true;
  btnRetFac: boolean = true;
  btnRetSol: boolean = true;
  btnRetCon: boolean = true;
  btnRetPqr: boolean = true;
  btnUpUsr: boolean = true;

  fil_mov: boolean = false;
  lis_pag: boolean = false;
  fil_data_mov: [] = [];
  listpagos: [] = [];

  fecsta: string = '';
  fecend: string = '';

  constructor(private modalServices: ModalServices, private _movimientosServices: MovimientosServices, private datePipe: DatePipe, private _pagosServices: PagosServices, private _socketServices: SocketService) {

  }

  ngOnInit() {
    this.getPagos();
    this.onPago();
  }

  onPago() {
    this._socketServices.upPag
      .subscribe(
        data => {
          let con = JSON.parse(JSON.stringify(data));
          this._pagosServices.getPagos(con.contrato, '0', '5')
            .then(
              data => {
                if (data['total'] > 0) {
                  this.lis_pag = true;
                  this.listpagos = data['pagos'];
                }
              }
            ).catch((error) => {
              console.log(error);
            });
        }
      );
  }

  getPagos() {
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this._pagosServices.getPagos(dispositivo.contrato, '0', '5')
      .then(
        data => {
          if (data['total'] > 0) {
            this.lis_pag = true;
            this.listpagos = data['pagos'];
          }
        }
      ).catch((error) => {
        console.log(error);
      });
  }

  openModal(id: string) {
    this.modalServices.open(id);
  }

  closeModal(id: string) {
    this.modalServices.close(id);
  }

  addUsuario() {
    this.usuariosView.crearUsuario();
    this.btnRetUsu = false;
  }

  addEncargado() {
    this.encargadosView.crearEncargados();
    this.btnRetEnc = false;
  }

  addPqr() {
    this.pqrsView.crearPqrs();
    this.btnRetPqr = false;
  }

  verUsuario() {
    this.btnUpUsr = false;
    this.btnRetUsu = false;
  }

  emitEventUsuarios(event: any) {
    this.btnRetUsu = false;
  }

  emitEventMovimientos(event: any) {
    this.btnRetMov = false;
  }

  emitEventMovimiento(event: any) {
    this.btnRetMov = true;
  }

  emitEventActualizacion(event: any) {
    this.btnRetEnc = false;
  }

  emitEventPqr(event: any) {
    this.btnRetEnc = false;
  }

  retornoListUsuarios() {
    this.btnRetUsu = true;
    this.usuariosView.retornoListUsuarios();
  }

  retornoListPqr() {
    this.btnRetPqr = true;
    this.pqrsView.retornoListPqr();
  }

  retornoListEncargados() {
    this.btnRetEnc = true;
    this.encargadosView.retornoListEncargados();
  }

  retornoListMovimientos() {
    this.btnRetMov = true;
    this.movimientosView.retornoListMovimientos();
  }

  retornoListMovimiento() {
    this.btnRetMov = false;
  }

  usuarioModulo(event: any) {
    this.retornoListUsuarios();
  }

  cargarSecciones(idx: string) {
    this.movimientosView.getMovimientos(idx, 0, 5, this.fecsta, this.fecend);
    //this.usuariosView.getUsuarios(idx);
    //this.encargadosView.getEncargados(idx);
    //this.pqrsView.getPqrs(idx);
    //this.facturasView.getCuentas(idx);
  }

  listMovimientos() {
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this.movimientosView.cargarMovimientos(dispositivo.cliente, 6, 10, this.fecsta, this.fecend);
  }

  filMovimientos(desde: number, limite: number, fecsta: string, fecend: string) {
    let data;
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this._movimientosServices.getFilMovimientos(dispositivo.cliente, desde, limite, fecsta, fecend).subscribe(
      resp => {
        if (resp[1].length > 0) {
          this.fil_mov = true;
          this.fil_data_mov = resp[1];
        }
        else {
          Swal.fire(
            'Sin Informacion',
            'No se registraron eventos',
            'info'
          )
        }
      }
    );
    return data;
  }

  filmovs() {
    let fecin = new Date(this.fecsta);
    let fecou = new Date(this.fecend);
    if (this.fecend != undefined && this.fecsta != undefined) {
      let inicial = `${this.datePipe.transform(new Date(this.fecsta), "yyyy-MM-dd HH:mm")}:00Z`;
      let final = `${this.datePipe.transform(new Date(this.fecend), "yyyy-MM-dd HH:mm")}:00Z`;
      if (fecin.getTime() <= fecou.getTime()) {
        this.filMovimientos(0, 100, `${inicial}`, `${final}`);
      }
      else {
        Swal.fire(
          'Tiempo Filtrado',
          'el tiempo inicial no puede ser mayor al tiempo final',
          'question'
        )
      }
    }
    else {
      Swal.fire(
        'Tiempo Filtrado',
        'El Intervalo de tiempo esta vacio',
        'question'
      )
      this.fil_mov = false;
    }
  }

  listEncargados() {

  }

  gdUsuario() {
    this.usuariosView.gdUsuario();
  }
}
