import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.models';
import { Injectable } from '@angular/core';
import { SocketService } from '../socket/socket.services';
import { LoginSocket } from '../socket/login.services';
import { ConfigServices } from './config.services';
import 'rxjs/Rx';

@Injectable()
export class LoginServices {

  url: string = '';
  nombre: string = '';

  isUserLoggedIn: boolean = false;
  usserLogged: User;

  constructor(private http: HttpClient, private _configService: ConfigServices, private _socketServices: SocketService, private _loginSocket: LoginSocket) {
    console.log("Login Services");
    this.isUserLoggedIn = false;
    this.url = this._configService.url;
  }

  login(body: any) {
    const url = `${this.url}/login_email`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let parser = new Promise((resolve, reject) => {
      this.http.post(url, body, { headers })
        .toPromise()
        .then(
          data => { // Success
            this.setUserLoggedIn(data['usuario'], data['token'], data['idusuario']);
            resolve(data);
          },
          msg => { // Error
            reject(msg.error['message']);
          }
        );
    });
    return parser;
  }

  setNombre(nombre: string) {
    this.nombre = nombre;
  }

  getNombre() {
    return this.nombre;
  }

  setUserLoggedIn(user: User, token: string, idUser: string) {
    this.isUserLoggedIn = true;
    this.usserLogged = user;
    this._loginSocket.iniciarSession(this.usserLogged, token, idUser);
  }

  setDispositivoInicial(dispositivo: any) {
    localStorage.setItem('dispositivo', JSON.stringify(dispositivo));
  }

  getUserLoggedIn() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  getUserLoggedInString() {
    return JSON.stringify(localStorage.getItem('currentUser'));
  }

  setUserLoggedOut() {
    this.isUserLoggedIn = false;
    this._loginSocket.cerrarSession(this.usserLogged);
  }
}
