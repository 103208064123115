import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { DispositivosServices } from '../../../services/dispositivos.services';
import { LoginServices } from '../../../services/login.services';
import { SocketService } from '../../../socket/socket.services';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dispositivos',
  templateUrl: './dispositivos.component.html',
  styleUrls: ['./dispositivos.component.css']
})

export class DispositivosComponent implements OnInit {

  data: boolean = false;
  dispositivos: any[] = [];
  dispositivos_list : any[] = [];
  posicion: number = 0;
  imagen: boolean = false;
  dps : any[] = [];
  dispositivosel :string = "";
  keyword = 'name';

  public searching: boolean = false;

  @Output() emitEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _dispositivosServices: DispositivosServices, private _loginServices: LoginServices, private router: Router, private _socketService: SocketService) { }

  ngOnInit() {
    this.cargarDispositivos();
    this.onDispositivo();
  }

  onDispositivo() {
    this._socketService.upDispositivo
      .subscribe(
        data => {
          console.log(data);
          this.cargarDispositivos();
        }
      );
  }

  cargarDispositivos() {
    this._dispositivosServices.getDispositivos()
      .then((data) => {
        this.dispositivos = data['contratos'];
        this.dispositivos_list = data['contratos'];
        let arr = [];
        this.dps = [];
        for (var i in this.dispositivos) {
          arr.push({ key: this.dispositivos[i].contrato, text: this.dispositivos[i].razonsocial});
          this.dps.push({ id: this.dispositivos[i].contrato, name: this.dispositivos[i].razonsocial});
        }
        localStorage.setItem('dispositivos', JSON.stringify(arr));
      })
      .catch((err) => {
        if (!err['ok']) {
          Swal.fire({
            title: 'Inicia Session',
            text: "Su Session ha expirado, ingrese nuevamente!",
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, salir!',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.value) {
              this._loginServices.setUserLoggedOut();
              this.router.navigate(['/login']);
            }
          })
        }
      });
  }

  verDispositivo(idx: string, posicion: number) {
    this._dispositivosServices.getDispositivos()
      .then(dat => {
        if (dat['total'] > 0) {
          let dispositivos = dat['contratos'];
          this._loginServices.setDispositivoInicial(dispositivos[posicion]);
        }
      })
      .catch((err) => {
        console.log(err);
        //Swal.fire(err);
      });

    this.emitEvent.emit(idx);
    this.posicion = posicion;
  }

  buscarDispositivos(termino: string) {
    let dispoArr = [];
    for (let dispositivo of this.dispositivos) {
      let lugar = dispositivo.lugar.toLowerCase();
      let contrato = dispositivo.cliente;
      if (lugar.indexOf(termino) >= 0) {
        dispoArr.push(dispositivo);
      }
      else if (contrato.indexOf(termino) >= 0) {
        dispoArr.push(dispositivo);
      }
    }
    return dispoArr;
  }

  selectEvent(event) {
    let uddFilPri = [];
    uddFilPri = this.dispositivos_list;

    uddFilPri = uddFilPri.filter((element) => {
      if(event.name != '') {
        return element.razonsocial == event.name || event.name == "";
      }
    });

    this.dispositivos = uddFilPri;
  }

  clearFiltro() {
    this.dispositivos = this.dispositivos_list;
  }
}
