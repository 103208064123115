import { Component, OnInit } from '@angular/core';
import { ModalServices } from '../../../../services/modal.services';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  constructor(private modalServices: ModalServices) { }

  ngOnInit() {
  }

  cambiarPin() {
    alert();
  }

  openModal(id: string) {
    this.modalServices.open(id);
  }

  closeModal(id: string) {
    this.modalServices.close(id);
  }
}
