import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { MovimientosServices } from '../../../services/movimientos.services';
import { MovimientoComponent } from '../movimiento/movimiento.component';
import { ConfirmacionComponent } from '../confirmacion/confirmacion.component';
import { SocketService } from '../../../socket/socket.services';
import { Dispositivo } from '../../../interface/interface';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.css']
})

export class MovimientosComponent implements OnInit {

  contrato: string = '';
  movimiento: any[] = [];
  movimientos: any[] = [];
  movfil: any[] = [];

  btnRetMov: boolean = true;
  selMovimientos: boolean = true;
  selMovimiento: boolean = false;
  selConfirmacion: boolean = false;
  existenMovimientos: boolean = false;
  numShops: number = 0;

  fecsta: string = `0000-00-00 00:00:00Z`;
  fecend: string = `0000-00-00 00:00:00Z`;

  filmovarr: any[] = [];
  inicio: number = 0;
  final: number = 5;

  _movServices: Subscription;

  iconos: string[] = [
    'fa-caret-right fa-2x',
    'fa-stopwatch fa-2x',
    'fa-check fa-2x',
    'fa-minus-circle fa-2x'];
  iconoColors: string[] = [
    'iconSuccess',
    'iconInfo',
    'iconWarning',
    'iconError'];

  @ViewChild(MovimientoComponent) movView: MovimientoComponent;
  @ViewChild(ConfirmacionComponent) conView: ConfirmacionComponent;

  @Input() dispositivo: Dispositivo;
  @Output() emitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitEventMovimiento: EventEmitter<any> = new EventEmitter<any>();

  @Input() public page: number = 1;
  //@Input() public numShops: number;
  @Input() public totalPages: number = 1;

  private numRegistros: number = 5;
  @Output() paginaEmitter: EventEmitter<number> = new EventEmitter();

  constructor(private _movimientosServices: MovimientosServices, private _socketService: SocketService) {

    let now = new Date();
    let mes = now.getMonth() + 1;
    let dia = now.getDate();

    let mon = '';
    let day = '';

    if (mes < 10) {
      mon = `0${mes}`;
    } else {
      mon = `${mes}`;
    }
    if (dia < 10) {
      day = `0${dia}`;
    } else {
      day = `${dia}`;
    }

    this.fecend = `${now.getFullYear()}-${mon}-${day} 23:59:59Z`;
  }

  ngOnInit() {
    this.getContrato();
    this.emitMovimiento();
    this.onMovimiento();
  }

  emitMovimiento() {
    this._socketService.addMovimiento
      .subscribe(
        data => {
          let dd = JSON.parse(JSON.stringify(data));
          let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
          if (dd.cliente == dispositivo.contrato) {
            this.cargarMovimientos(dispositivo.contrato, 0, 10, this.fecsta, this.fecend);
          }
        }
      );
  }

  onMovimiento() {
    /*this._socketService.upMov
      .subscribe(
        data => {
          let activos: any = [];
          let dispositivos = JSON.parse(localStorage.getItem('dispositivos'));
          dispositivos.forEach(function(elemento: any) {
            activos.push(elemento.key);
          });
          let contrato = JSON.parse(JSON.stringify(data));
          if (activos.indexOf(contrato.cliente) == 0) {
            this.cargarMovimientos(contrato.cliente, 0, 10, this.fecsta, this.fecend);
          }
        }
      );*/
  }

  /*Se ejecuta al cargar la pagina*/
  getContrato() {
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this.cargarMovimientos(dispositivo['contrato'], 0, 5, this.fecsta, this.fecend);
  }

  cargarMovimientos(contrato: string, desde: number, limite: number, fecsta: string, fecend: string) {
    if (fecsta == '') {
      fecsta = this.fecsta;
    }

    this._movimientosServices.getMovimientos(contrato, desde, limite, fecsta, fecend)
      .then(
        data => {
          if (data['total'] > 0) {
            this.existenMovimientos = true;
          }
          else {
            this.existenMovimientos = false;
          }
          this.numShops = data['total'];
          this.movimientos = data['movimientos'];
          this.filmovarr = this.movimientos.slice(0, 5);
          if (this.numShops < this.numRegistros) {
            this.numRegistros = 1;
          }
          this.totalPages = Math.round(this.numShops / this.numRegistros);
        }
      )
      .catch((error) => {
        console.log(error);
        Swal.fire(
          'MOVIMIENTOS',
          `Sin Movimientos`,
          'error'
        )
      });
  }

  siguiente() {
    this.page = this.page + 1;
    this.inicio = this.inicio + 5;
    this.final = this.final + 5;
    this.filmovarr = this.movimientos.slice(this.inicio, this.final);
    console.log("siguiente");
  }

  anterior() {
    this.page--;
    this.inicio = this.inicio - 5;
    this.final = this.final - 5;
    this.filmovarr = this.movimientos.slice(this.inicio, this.final);
    console.log("anterior");
  }

  cargarListMovimientos(event: any) {
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this.cargarMovimientos(dispositivo.contrato, 0, 10, this.fecsta, this.fecend);
  }

  verMovimiento(idx: string, confirmacion: string) {
    if (confirmacion == '1') {
      this.movView.cargarMovimiento(idx);
      this.btnRetMov = false;
      this.selMovimientos = false;
      this.selMovimiento = true;
      this.emitEventMovimientos();
    }

    if (confirmacion == '2' || confirmacion == '3') {
      this.conView.cargarMovimiento(idx);
      this.btnRetMov = false;
      this.selMovimientos = false;
      this.selConfirmacion = true;
    }
  }

  emitEventMovimientos() {
    this.emitEvent.emit(false);
  }

  retornoListMovimientos() {
    this.selMovimientos = true;
    this.selMovimiento = false;
  }

  retornoListMovimiento(event: any) {
    this.btnRetMov = true;
    this.selMovimientos = true;
    this.selMovimiento = false;
    this.selConfirmacion = false;
  }

  listMovimientos() {
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this.cargarMovimientos(dispositivo.contrato, 0, 10, this.fecsta, this.fecend);
  }

  getMovimientos(idx: string, desde: number, limite: number, fecsta: string, fecend: string) {
    this.cargarMovimientos(idx, desde, limite, fecsta, fecend);
  }

}
