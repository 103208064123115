import { Injectable, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnInit {

  status: any = [];

  addMovimiento = this.socket.fromEvent<string[]>('addMovimiento');

  upPag = this.socket.fromEvent<string[]>('upPago');

  addDispositivo = this.socket.fromEvent<string[]>('addDispositivo');
  upDispositivo = this.socket.fromEvent<string[]>('upDispositivo');

  addUsuario = this.socket.fromEvent<string[]>('addUsuario');
  upUsuario = this.socket.fromEvent<string[]>('upUsuario');

  addEncargado = this.socket.fromEvent<string[]>('addEncargado');
  upEncargado = this.socket.fromEvent<string[]>('upEncargado');

  addPqr = this.socket.fromEvent<string[]>('addPqr');
  upPqr = this.socket.fromEvent<string[]>('upPqr');

  addFactura = this.socket.fromEvent<string[]>('addFactura');
  upFactura = this.socket.fromEvent<string[]>('upFactura');

  constructor(private socket: Socket) {

  }

  ngOnInit() {

  }

  upMovimiento(idx: string) {
    this.socket.emit('upMovimiento', idx);
  }
}
