import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { LoginServices } from '../../services/login.services';
import { DispositivosServices } from '../../services/dispositivos.services';
import { ModalServices } from '../../services/modal.services';
import { RegistroServices } from '../../services/registro.services';
import { UsuariosServices } from '../../services/usuarios.services';

import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  moduleId: module.id,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {

  formClave: FormGroup;
  formLogin: FormGroup;
  formRegister: FormGroup;
  formPin: FormGroup;
  formStatus: boolean = false;
  codigoblock: boolean = true;
  onsubmit: boolean = true;
  pin: boolean = false;
  onregister: boolean = false;
  formStatusClave: boolean = false;

  constructor(
    private router: Router,
    private modalServices: ModalServices,
    private _loginService: LoginServices,
    private _registroService: RegistroServices,
    private _usuariosService: UsuariosServices,
    private _dispositivosService: DispositivosServices) {

    this.formLogin = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'pin': new FormControl('', [Validators.required, Validators.minLength(3)])
    })

    this.formRegister = new FormGroup({
      'telefono': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'codigo': new FormControl('', [Validators.minLength(3)])
    })

    this.formPin = new FormGroup({
      'pin1': new FormControl('', [Validators.required, Validators.minLength(4)]),
      'pin2': new FormControl('', [Validators.required, Validators.minLength(4)])
    });

    this.formPin.controls['pin2'].setValidators([
      Validators.required,
      this.validarClave.bind(this.formPin)
    ]);

    this.formClave = new FormGroup({
      'clave1': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'clave2': new FormControl('', [Validators.required, Validators.minLength(3)])
    })
  }

  validarClave(control: FormControl): { [s: string]: boolean } {
    let forma: any = this;
    if (control.value !== forma.controls['pin1'].value) {
      return {
        validarclave: true
      }
    }
    return null;
  }

  activacion() {
    this._registroService.activacion(this.formRegister.controls['telefono'].value, this.formRegister.controls['codigo'].value)
      .then(
        data => {
          if (data['ok']) {
            Swal.fire('Por favor ingrese su pin de seguridad que le permitira navegar en la version Web y Movil de telesentinel, recuerde este PIN es de 4 Digitos de uso privado.');
          }
          this.pin = true;
          localStorage.setItem('tokenUser', data['token']);
          localStorage.setItem('idUser', data['idusuario']);
        }).catch(
          error => {
            Swal.fire(error);
          }
        );
  }

  onSubmit() {
    if (this.onsubmit) {
      this.registrarse();
    }
    else {
      this.activacion();
    }
  }

  reenviarsms() {

  }

  registrarse() {
    if (this.formRegister.controls['telefono'].invalid) {
      this.formStatus = true;
    } else {
      if (this.formRegister.status == 'VALID') {
        this.formStatus = false;
        this._registroService.registro(this.formRegister.controls['telefono'].value)
          .then(
            data => {
              if (!data['estado']) {
                Swal.fire('se envio mensaje de Activacion SMS a su dispositivo celular!');
                this.onregister = true;
              } else {
                this.onregister = false;
              }
              this.codigoblock = false;
              this.onsubmit = false;
            }
          ).catch(
            error => {
              Swal.fire(error);
            }
          )
      }
      else {
        this.formStatus = true;
      }
    }
  }

  registroLogin() {

    if (this.formPin.status == 'VALID') {
      this._usuariosService.upUsuarioPin(
        this.formPin.controls['pin1'].value,
        localStorage.getItem('idUser')
      )
        .then(
          data => {
            this._loginService.setUserLoggedIn(
              data['usuario'],
              localStorage.getItem('tokenUser'),
              data['idusuario']
            );
            this._dispositivosService.getDispositivos()
              .then(dat => {
                if (dat['total'] >= 0) {
                  this._loginService.setDispositivoInicial(dat['dispositivos'][0]);
                  Swal.fire('Bienvenido!');
                  this.router.navigate(['/dashboard']);
                }
              })
              .catch((err) => {
                console.log("1");
                Swal.fire(err);
                this.formStatus = true;
              });
          }).catch((error) => {
            console.log("2");
            Swal.fire(error);
          });
    } else {
      Swal.fire('Los codigos no son iguales');
    }
  }

  ingresar() {
    if (this.formLogin.controls['email'].invalid) {
      this.formStatus = true;
    } else {
      if (this.formLogin.status == 'VALID') {
        this._loginService.login(this.formLogin.value)
          .then(data => {
            if (data['ok']) {
              if(data['usuario'].pin == '0000') {
                Swal.fire('Debe cambiar su contraseña!');
                this.openModal('upClave');
              }
              else {
                if (data['usuario'].perfil == 'personal') {
                  this._dispositivosService.getDispositivos()
                    .then(dat => {
                      localStorage.setItem('dispositivos', JSON.stringify(dat['contratos']));
                      this._loginService.setDispositivoInicial(dat['contratos'][0]);
                      Swal.fire('Bienvenido!');
                      this.router.navigate(['/dashboard']);
                    })
                    .catch((err) => {
                      Swal.fire(err);
                      this.formStatus = true;
                    });
                }
                if (data['usuario'].perfil == 'coorporativo') {
                  this._dispositivosService.getDispositivos()
                    .then(dat => {
                      localStorage.setItem('dispositivos', JSON.stringify(dat['contratos']));
                      this._loginService.setDispositivoInicial(dat['contratos'][0]);
                      Swal.fire('Bienvenido!');
                      this.router.navigate(['/coorporativo']);
                    })
                    .catch((err) => {
                      Swal.fire(err);
                      this.formStatus = true;
                    });
                }
              }
            }
          }).catch((error) => {
            Swal.fire('Servidor Desconectado \n' + error);
            this.formStatus = true;
          });
      }
    }
  }


  openModal(id: string) {
    this.modalServices.open(id);
  }

  closeModal(id: string) {
    this.modalServices.close(id);
  }

  actualizarClave() {
    if (this.formClave.controls['clave1'].invalid && this.formClave.controls['clave2'].invalid) {
      this.formStatusClave = true;
    } else {
      if (this.formClave.status == 'VALID') {        
        if (this.formClave.controls['clave1'].value == this.formClave.controls['clave2'].value) {
          this.formStatusClave = false;
          this._usuariosService.upUsuarioPin(this.formClave.controls['clave1'].value, localStorage.getItem('idUser'))
            .then(info => {
              if (info['ok']) {
                this.formClave.reset();
                this.closeModal('upClave');
                Swal.fire(
                  'Cambio Exitoso',
                  'Clave Actualizada',
                  'success'
                  )

              let usr = JSON.parse(localStorage.getItem('currentUser'));

              if (usr.perfil == 'personal') {
                this._dispositivosService.getDispositivos()
                  .then(dat => {
                    localStorage.setItem('dispositivos', JSON.stringify(dat['contratos']));
                    this._loginService.setDispositivoInicial(dat['contratos'][0]);
                    Swal.fire('Bienvenido!');
                    this.router.navigate(['/dashboard']);
                  })
                  .catch((err) => {
                    Swal.fire(err);
                    this.formStatus = true;
                  });
              }

              if (usr.perfil == 'coorporativo') {
                this._dispositivosService.getDispositivos()
                  .then(dat => {
                    localStorage.setItem('dispositivos', JSON.stringify(dat['contratos']));
                    this._loginService.setDispositivoInicial(dat['contratos'][0]);
                    Swal.fire('Bienvenido!');
                    this.router.navigate(['/coorporativo']);
                  })
                  .catch((err) => {
                    Swal.fire(err);
                    this.formStatus = true;
                  });
              }


              }
            })
            .catch(error => {
              console.log(error);
            });
        }
        else {
          this.formStatusClave = true;
          Swal.fire(
            'Clave Incorrecta',
            'Confirme la clave nuevamente',
            'info'
          )
          this.formClave.reset();
        }
      }
    }
  }
}
