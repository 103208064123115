import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigServices } from './config.services';
import 'rxjs/Rx';

@Injectable()

export class PagosServices {
  url: string = "";

  constructor(private http: HttpClient, private _configServices: ConfigServices) {
    console.log("Pagos Clientes");
    this.url = this._configServices.url;
  }

  getPagos(contrato: string, desde: string, limite: string) {
    return new Promise((resolve, reject) => {

      const params = new HttpParams().set('desde', desde).set('limite', limite).set('contrato', contrato);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.post(`${this.url}/pagos`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }
}
