import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { DispositivosServices } from '../../../services/dispositivos.services';
import { LoginServices } from '../../../services/login.services';
import { ModalServices } from '../../../services/modal.services';
import { UsuariosServices } from '../../../services/usuarios.services';
import { Dispositivo } from '../../../interface/interface';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./nabvar.component.css']
})

export class NavbarComponent implements OnInit {

  @Input() dispositivo: Dispositivo;

  usuario: any = [];
  idx: number = 0;
  lugar: string = "";

  permisoUser : boolean = false;

  formClave: FormGroup;
  formCrearUsuario : FormGroup;
  formStatus: boolean = false;

  coorporativo : boolean = false;


  constructor(private _dispositivosServices: DispositivosServices, private _loginService: LoginServices, private router: Router, private modalServices: ModalServices, private _usuariosService: UsuariosServices) {
    this.usuario = this._loginService.getUserLoggedIn();

    this.formClave = new FormGroup({
      'clave1': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'clave2': new FormControl('', [Validators.required, Validators.minLength(3)])
    })

    this.formCrearUsuario = new FormGroup({
      'nombre': new FormControl('', [Validators.required, Validators.minLength(2)]),
      'telefono': new FormControl('', [Validators.required, Validators.minLength(7)]),
      'cedula': new FormControl('', [Validators.required, Validators.minLength(5)]),
      'email': new FormControl('', [Validators.required, Validators.minLength(5)]),
      'permiso': new FormControl('', [Validators.required]),
      'perfil': new FormControl('', [Validators.required]),
      'ciudad': new FormControl('', [Validators.required])
    })
  }

  ngOnInit() {
    let dis = localStorage.getItem('dispositivo');
    if(dis != 'undefined') {
      this.dispositivo = JSON.parse(dis);
      this.lugar = this.dispositivo['razonsocial'];
    }
    let usr = JSON.parse(localStorage.getItem('currentUser'));
    if(usr.rol == 'ADMIN_ROL') {
      this.permisoUser = true;
    }
    if(usr.perfil == 'personal') {
      this.coorporativo = true;
    }
  }

  setLugar(idx: string) {
    this._dispositivosServices.getDispositivo(idx)
      .then(
        (data) => {
          if(data > 0) {
            this.dispositivo = data['dispositivos'][0];
            this.lugar = this.dispositivo['razonsocial'];
          }
          
        })
      .catch(
        (error) => {
          console.log(error);
        });
  }

  crearUsuario() {
    if (this.formCrearUsuario.status == 'INVALID') {
      this.formStatus = true;
    }
  
    if (this.formCrearUsuario.status == 'VALID') {
      this.formStatus = false;
      this._usuariosService.crearUsuario(this.formCrearUsuario.value)
            .then(info => {
              console.log(info);
              if (info['ok']) {
                this.formCrearUsuario.reset();
                this.closeModal('creacionUsuario');
                Swal.fire(
                  'Creado Exitosamente',
                  'Validar Trafico',
                  'success'
                )
              }
            })
            .catch(error => {
              console.log(error);
            });
    }
  }

  logout() {
    this._loginService.setUserLoggedOut();
    this.router.navigate(['/login']);
  }

  openModal(id: string) {
    this.modalServices.open(id);
  }

  closeModal(id: string) {
    this.modalServices.close(id);
  }

  actualizarClave() {
    if (this.formClave.controls['clave1'].invalid && this.formClave.controls['clave2'].invalid) {
      this.formStatus = true;
    } else {
      if (this.formClave.status == 'VALID') {
        if (this.formClave.controls['clave1'].value == this.formClave.controls['clave2'].value) {
          this.formStatus = false;
          this._usuariosService.upUsuarioPin(this.formClave.controls['clave1'].value, localStorage.getItem('idUser'))
            .then(info => {
              if (info['ok']) {
                this.formClave.reset();
                this.closeModal('cambioClave');
                Swal.fire(
                  'Cambio Exitoso',
                  'Clave Actualizada',
                  'success'
                )
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
        else {
          this.formStatus = true;
          Swal.fire(
            'Clave Incorrecta',
            'Confirme la clave nuevamente',
            'info'
          )
          this.formClave.reset();
        }
      }
    }
  }
}
