import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { EncargadosServices } from '../../../services/encargados.services';
import { VerEncargadoComponent } from './verencargado/verencargado.component';
import { CrearEncargadoComponent } from './crearencargado/crearencargado.component';
import { Encargado } from '../../../interface/interface';

@Component({
  selector: 'app-encargados',
  templateUrl: './encargados.component.html',
  styleUrls: ['./encargados.component.css']
})

export class EncargadosComponent implements OnInit {

  idx: number = 0;
  usuarios: Encargado;
  totalPages: number = 1;
  numResults: number = 5;

  listUsuarios: boolean = true;
  verEncargado: boolean = false;
  crearEncargado: boolean = false;
  editarEncargado: boolean = false;
  existenEncargados: boolean = false;
  btnRetEnc: boolean = true;
  numShops: boolean = true;

  @ViewChild(VerEncargadoComponent) encargadoView: VerEncargadoComponent;
  @ViewChild(CrearEncargadoComponent) encargadoCrearView: CrearEncargadoComponent;
  @Output() emitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() usuarioModulo: EventEmitter<any> = new EventEmitter<any>();
  @Output() paginaEmitter: EventEmitter<number> = new EventEmitter();
  @Input() public page: number = 1;

  constructor(private _encargadosServices: EncargadosServices) {

  }

  ngOnInit() {
    let contrato = JSON.parse(localStorage.getItem('dispositivo'));
    this._encargadosServices.getEncargados(contrato.contrato)
      .then((data) => {
        if (data['total'] > 0) {
          this.usuarios = data['encargados'];
          this.existenEncargados = true;
        }
      })
      .catch((error) => console.log(error));
  }

  emitEventActualizacion() {
    this.emitEvent.emit(false);
  }

  getEncargados(contrato: string) {
    this._encargadosServices.getEncargados(contrato)
      .then((data) => {
        if (data['total'] > 0) {
          this.usuarios = data['encargados'];
          this.existenEncargados = true;
        } else {
          this.existenEncargados = false;
        }

      })
      .catch((error) => console.log(error));
  }

  verEncargados(idx: string) {
    this.verEncargado = true;
    this.listUsuarios = false;
    this.crearEncargado = false;
    this.editarEncargado = false;
    this.encargadoView.verEncargado(idx);
    this.emitEventEncargados(event);
  }

  retornoListEncargados() {
    this.listUsuarios = true;
    this.crearEncargado = false;
    this.verEncargado = false;
    this.editarEncargado = false;
  }

  emitEventEncargados(event: any) {
    this.emitEvent.emit(false);
  }

  crearEncargados() {
    this.listUsuarios = false;
    this.crearEncargado = true;
    this.verEncargado = false;
    this.editarEncargado = false;
  }

  siguiente() {
    this.page++;
    this.pasarPagina();
  }

  anterior() {
    this.page--;
    this.pasarPagina();
  }

  pasarPagina() {
    let desde = 1;
    let limite = 5;
    if (this.page == 2) {
      desde = 6;
      limite = 10;
    }
    else if (this.page == 3) {
      desde = 11;
      limite = 15;
    }
    else if (this.page == 4) {
      desde = 16;
      limite = 20;
    }
    else {
      desde = 1;
      limite = 5;
    }
  }

  addEncargado() {
    this.btnRetEnc = false;
    this.crearEncargados();
  }

  cargarListEncargados(event: any) {
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this.getEncargados(dispositivo.cliente);
    this.btnRetEnc = true;
    this.listUsuarios = true;
    this.crearEncargado = false;
    this.verEncargado = false;
  }
}
