import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabModule } from 'angular-tabs-component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { DatePipe, HashLocationStrategy } from '@angular/common';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { DateRangePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

import { MatFormFieldModule, MatInputModule, MatButtonModule, MatCheckboxModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { DialogOverviewExampleDialog } from './component/dashboard/popup-course/popup-course.component';

// Rutas
import { AppRoutingModule } from './app.routes';

// Servicios
import { ConfigServices } from './services/config.services';
import { LoginServices } from './services/login.services';
import { RegistroServices } from './services/registro.services';
import { UsuariosServices } from './services/usuarios.services';
import { EncargadosServices } from './services/encargados.services';
import { MovimientosServices } from './services/movimientos.services';
import { DispositivosServices } from './services/dispositivos.services';
import { XsegundoService } from './services/xsegundo.service';
import { CuentasServices } from './services/cuentas.services';
import { PagosServices } from './services/pagos.services';
import { PqrServices } from './services/pqr.services';
import { TarjetaServices } from './services/tarjeta.services';
import { UsuarioDispositivosServices } from './services/usuario_dispositivos.services';
import { ModalServices } from './services/modal.services';

// Component
import { AppComponent } from './app.component';
import { NavbarComponent } from './component/shared/navbar/navbar.component';
import { ClockComponent } from './component/shared/clock/clock.component';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { MovimientosComponent } from './component/dashboard/movimientos/movimientos.component';
import { CuentaComponent } from './component/dashboard/cuenta/cuenta.component';
import { PqrComponent } from './component/dashboard/pqr/pqr.component';
import { FooterComponent } from './component/shared/footer/footer.component';
import { DispositivosComponent } from './component/dashboard/dispositivos/dispositivos.component';
import { DispositivoComponent } from './component/dashboard/dispositivo/dispositivo.component';
import { ConfiguracionComponent } from './component/dashboard/configuracion/configuracion.component';
import { MovimientoComponent } from './component/dashboard/movimiento/movimiento.component';
import { ModulosComponent } from './component/dashboard/modulos/modulos.component';
import { ModalComponent } from './component/dashboard/modal/modal.component';
import { UsuariosComponent } from './component/dashboard/usuarios/usuarios.component';
import { CrearusuarioComponent } from './component/dashboard/usuario/crearusuario/crearusuario.component';
import { EditarusuarioComponent } from './component/dashboard/usuario/editarusuario/editarusuario.component';
import { VerusuarioComponent } from './component/dashboard/usuario/verusuario/verusuario.component';
import { VerEncargadoComponent } from './component/dashboard/encargados/verencargado/verencargado.component';
import { CrearEncargadoComponent } from './component/dashboard/encargados/crearencargado/crearencargado.component';
import { VerPqrComponent } from './component/dashboard/pqr/verpqr/verpqr.component';
import { CrearPqrComponent } from './component/dashboard/pqr/crearpqr/crearpqr.component';
import { EncargadosComponent } from './component/dashboard/encargados/encargados.component';
import { PerfilComponent } from './component/dashboard/configuracion/perfil/perfil.component';
import { PagosComponent } from './component/dashboard/configuracion/pagos/pagos.component';
import { ConfirmacionComponent } from './component/dashboard/confirmacion/confirmacion.component';
import { CoorporativoComponent } from './component/coorporativo/coorporativo.component';
import { MenuComponent } from './component/coorporativo/menu/menu.component';
import { SearchPipe } from './component/search.pipe';

const config: SocketIoConfig = { url: 'https://app.telesentinel.com:3001', options: {} };
//const config: SocketIoConfig = { url: 'https://192.1.1.30:3001', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    MovimientosComponent,
    CuentaComponent,
    PqrComponent,
    UsuariosComponent,
    FooterComponent,
    DispositivosComponent,
    DispositivoComponent,
    DashboardComponent,
    ClockComponent,
    ConfiguracionComponent,
    MovimientoComponent,
    ModulosComponent,
    CrearusuarioComponent,
    EditarusuarioComponent,
    VerusuarioComponent,
    VerEncargadoComponent,
    EncargadosComponent,
    PerfilComponent,
    PagosComponent,
    CrearPqrComponent,
    VerPqrComponent,
    CrearEncargadoComponent,
    ConfirmacionComponent,
    CoorporativoComponent,
    DialogOverviewExampleDialog,
    ModalComponent,
    MenuComponent
  ],
  imports: [
    NgbModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TabModule,
    SocketIoModule.forRoot(config),
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    MatButtonModule,
    MatCheckboxModule,
    AutocompleteLibModule,
    DateRangePickerModule,
    DateTimePickerModule
  ],
  providers: [
    ConfigServices,
    LoginServices,
    RegistroServices,
    UsuariosServices,
    EncargadosServices,
    MovimientosServices,
    DispositivosServices,
    XsegundoService,
    CuentasServices,
    PagosServices,
    PqrServices,
    TarjetaServices,
    UsuarioDispositivosServices,
    ModalServices,
    DatePipe,
    SearchPipe,
    FormsModule,
    HashLocationStrategy,
  ],
  entryComponents: [DialogOverviewExampleDialog],
  bootstrap: [AppComponent],
})

export class AppModule { }
