import { Component } from '@angular/core';

@Component({
  selector: 'app-dispositivo',
  templateUrl: './dispositivo.component.html',
  styleUrls: ['./dispositivo.component.css']
})
export class DispositivoComponent {

  constructor() {

  }

  cargarMovimiento(idx: string) {
    /*console.log(this._dispositivosServices.getDispositivo(parseInt(idx)));
    this.dis = this._dispositivosServices.getDispositivo(parseInt(idx));*/
  }
}
