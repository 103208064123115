import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigServices } from './config.services';
import 'rxjs/Rx';

@Injectable()

export class UsuarioDispositivosServices {

  url: string = "";

  constructor(private http: HttpClient, private _configServices: ConfigServices) {
    console.log("UsuarioContrato services");
    this.url = this._configServices.url;
  }

  getUsuarioDispositivos(idx1: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('idusuario', idx1);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.get(`${this.url}/usuario_dispositivos`, { headers, params })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }

  upUsuarioDispositivos(idx: string, op: string) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('estado', op);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('tokenUser')
      });

      this.http.put(`${this.url}/usuario_dispositivos/${idx}`, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
  }
}
