import { Component, OnInit } from '@angular/core';
import { LoginServices } from '../../../services/login.services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {

  usuario: any = [];
  idx: number = 0;
  lugar: string = "";

  constructor(private _loginService: LoginServices, private router: Router) {
    this.usuario = this._loginService.getUserLoggedIn();
  }

  ngOnInit() {

  }
}
