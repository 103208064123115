import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TarjetaServices } from '../../../../services/tarjeta.services';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {

  crearCard: boolean = false;
  existenTarjetas: boolean = false;
  formCrearTarjeta: FormGroup;
  formStatus: boolean = false;
  show: boolean = true;
  hide: boolean = false;
  tarjetas: any[] = [];

  mesesven: any[] = [];
  aniosven: any[] = [];

  @Output() retornoListCards: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _tarjetaServices: TarjetaServices) {
    this.formCrearTarjeta = new FormGroup({
      'numero_tarjeta': new FormControl('', [Validators.required]),
      'mes': new FormControl('', [Validators.required]),
      'anio': new FormControl('', [Validators.required]),
      'nombre_tarjeta': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'cvc': new FormControl('', [Validators.required]),
    })

    let d = '';
    for (let j = 0, i = 1; i <= 12; i++ , j++) {
      if (i < 10) {
        d = `0${i}`;
      }
      else {
        d = `${i}`;
      }

      this.mesesven[j] = d;
    }

    for (let j = 0, i = 2019; i <= 2036; i++ , j++) {
      this.aniosven[j] = i
    }
  }

  ngOnInit() {
    this.getTarjetas();
  }


  getTarjetas() {
    this._tarjetaServices.getTarjetas('0', '5')
      .then((data) => {
        if (data['total'] > 0) {
          this.existenTarjetas = true;
          this.tarjetas = data['tarjetas'];
        } else {
          this.existenTarjetas = false;
        }
      })
      .catch((error) => console.log(error));
  }

  addCard() {
    this.crearCard = true;
    if (this.formCrearTarjeta.status == 'VALID') {
      this._tarjetaServices.crearTarjeta(
        this.formCrearTarjeta.value['numero_tarjeta'],
        this.formCrearTarjeta.value['mes'],
        this.formCrearTarjeta.value['anio'],
        this.formCrearTarjeta.value['nombre_tarjeta'],
        this.formCrearTarjeta.value['cvc']
      ).then((data) => {
        if (data['statusCode'] == 200) {
          Swal.fire('Ingresada Correctamente');
          this.formCrearTarjeta.reset();
          this.cancelCard();
        }
        else {
          Swal.fire(data['message']);
        }
      }).catch((error) => {
        console.log(error);
        Swal.fire('Todos los campos son obligatorios');
      });
    }
  }

  predeterminar(accion, idx) {
    this._tarjetaServices.upTarjeta(idx, accion)
      .then((data) => {
        if (data['ok']) {
          this.getTarjetas();
          /*Swal.fire(
            'Actualizada!',
            'Su tarjeta fue actualizada.',
            'success'
          );*/
        }
      }).catch((error) => {
        console.log(error);
        /*Swal.fire(
          'Alto!',
          error,
          //error.error.err['message'],
          'error'
        );*/
      });
  }


  uppercase(obj) {
    obj.value = obj.value.toUpperCase();
  }

  cancelCard() {
    this.crearCard = false;
    this.retornoListCards.emit();
    this.getTarjetas();
  }

  delCard(idx: string) {
    Swal.fire({
      title: '¿Eliminar Tarjeta?',
      text: "¿Desea remover este medio de pago?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, remover!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this._tarjetaServices.delTarjeta(idx)
          .then((data) => {
            if (data['ok']) {
              this.getTarjetas();
              Swal.fire(
                'Eliminada!',
                'Su tarjeta fue removida.',
                'success'
              );
            }
          }).catch((error) => {
            Swal.fire(
              'Eliminada!',
              error,
              'success'
            );
          });
      }
    })
  }
}
