import { Component, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { VerPqrComponent } from './verpqr/verpqr.component';
import { CrearPqrComponent } from './crearpqr/crearpqr.component';
import { PqrServices } from '../../../services/pqr.services';

@Component({
  selector: 'app-pqr',
  templateUrl: './pqr.component.html',
  styleUrls: ['./pqr.component.css']
})
export class PqrComponent {

  icon: boolean = false;
  faicon: string = 'fa fa-chevron-down';

  idx: number = 0;
  listPqr: boolean = true;
  crearPqr: boolean = false;
  verPqr: boolean = false;
  existenPqrs: boolean = false;

  pqrs: any[];
  totalPages: number = 1;

  @ViewChild(VerPqrComponent) pqrView: VerPqrComponent;
  @ViewChild(CrearPqrComponent) pqrCrearView: CrearPqrComponent;
  @Output() emitEvent: EventEmitter<any> = new EventEmitter<any>();

  numShops: boolean = true;

  @Output() usuarioModulo: EventEmitter<any> = new EventEmitter<any>();

  @Input() public page: number = 1;
  @Output() paginaEmitter: EventEmitter<number> = new EventEmitter();

  private numResults: number = 5;
  btnRetPqr: boolean = true;

  constructor(private _pqrServices: PqrServices) {

  }

  ngOnInit() {
    let contrato = JSON.parse(localStorage.getItem('dispositivo'));
    this._pqrServices.getPqrs('0', '5', contrato.contrato)
      .then((data) => {
        if (data['total'] > 0) {
          this.existenPqrs = true;
          this.pqrs = data['pqrs'];
        }
      })
      .catch((error) => console.log(error));
  }


  emitEventPqr(event: any) {
    this.emitEvent.emit(false);
  }

  verPqrs(idx: string) {
    this.verPqr = true;
    this.listPqr = false;
    this.crearPqr = false;
    this.pqrView.verPqr(idx);
    this.emitEventPqr(event);
  }

  retornoListPqr() {
    this.listPqr = true;
    this.crearPqr = false;
    this.verPqr = false;
  }

  crearPqrs() {
    this.crearPqr = true;
    this.verPqr = false;
    this.listPqr = false;
  }


  siguiente() {
    this.page++;
    this.pasarPagina();
  }

  anterior() {
    this.page--;
    this.pasarPagina();
  }

  pasarPagina() {
    let desde = 1;
    let limite = 5;
    if (this.page == 2) {
      desde = 6;
      limite = 10;
    }
    else if (this.page == 3) {
      desde = 11;
      limite = 15;
    }
    else if (this.page == 4) {
      desde = 16;
      limite = 20;
    }
    else {
      desde = 1;
      limite = 5;
    }

    let usuarios = JSON.parse(localStorage.getItem('usuarios'));
    console.log(usuarios);
    //this.cargarMovimientos(dispositivo.cliente, desde, limite);
  }

  addPqr() {
    this.listPqr = false;
    this.btnRetPqr = false;
    this.crearPqrs();
  }

  cargarListPqr(event: any) {
    this.listPqr = true;
    this.btnRetPqr = true;
    this.crearPqr = false;
    this.verPqr = false;
    let dispositivo = JSON.parse(localStorage.getItem('dispositivo'));
    this.getPqrs(dispositivo.contrato);
  }

  getPqrs(contrato: string) {
    this._pqrServices.getPqrs('0', '5', contrato)
      .then((data) => {
        if (data['total'] > 0) {
          this.existenPqrs = true;
          this.pqrs = data['pqrs'];
        }
        else {
          this.existenPqrs = false;
        }
      })
      .catch((error) => console.log(error));
  }
}
