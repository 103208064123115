import { Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MovimientoSocket extends Socket {

  upMovimiento = this.fromEvent<string[]>('upMovimiento');

  constructor() {
    super({ url: "https://app.telesentinel.com:3001/skmovimiento", options: {} });
  }

  ngOnInit() {
    this.connect();
  }
}
