import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../models/user.models';
import { Socket } from 'ngx-socket-io';
import { ConfigServices } from './config.services';
import 'rxjs/Rx';

@Injectable()
export class RegistroServices {

  url: string = "";
  status: boolean = false;
  errorCode: number = 200;
  errorMessage: string = '';
  nombre: string = '';

  isUserLoggedIn: boolean = false;
  usserLogged: User;

  constructor(private http: HttpClient, private socket: Socket, private _configServices: ConfigServices) {
    console.log("Registro Services");
    this.isUserLoggedIn = false;
    this.url = this._configServices.url;
  }

  registro(tel: string) {

    const url = `${this.url}/registro`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new HttpParams().set('telefono', tel);
    return new Promise((resolve, reject) => {
      this.http.post(url, body, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg.error['message']);
          }
        );
    });
  }

  activacion(tel: string, codigo: string) {
    const url = `${this.url}/codigo`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const params = new HttpParams().set('telefono', tel).set('codigo', codigo);
    return new Promise((resolve, reject) => {
      this.http.post(url, params, { headers })
        .toPromise()
        .then(
          data => { // Success
            resolve(data);
          },
          msg => { // Error
            reject(msg.error['message']);
          }
        );
    });

  }
}
