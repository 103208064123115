import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EncargadosServices } from '../../../../services/encargados.services';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-verencargado',
  templateUrl: './verencargado.component.html',
  styleUrls: ['./verencargado.component.css']
})

export class VerEncargadoComponent {

  encargado: any[] = [];
  formActualizarEncargado: FormGroup;

  @Output() cargarListEncargados: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _encargadosServices: EncargadosServices) {
    this.formActualizarEncargado = new FormGroup({
      'nombre': new FormControl('', [Validators.required, Validators.minLength(4)]),
      'telefono1': new FormControl('', [Validators.required, Validators.minLength(7)]),
      'telefono2': new FormControl('', [Validators.required, Validators.minLength(7)]),
      'descripcion': new FormControl('', [Validators.required, Validators.minLength(10)]),
      'cdi': new FormControl('', [Validators.required, Validators.minLength(10)]),
      'prioridad': new FormControl('', [Validators.required])
    })
  }

  verEncargado(idx1: string) {
    this._encargadosServices.getEncargado(idx1)
      .then((data) => {
        this.encargado = data['encargado'][0];
      })
      .catch((error) => console.log(error));
  }

  retornoUsr() {
    this.cargarListEncargados.emit();
  }
}
