import { Component, ViewChild, OnInit } from '@angular/core';

/*Componentes*/
import { DispositivosComponent } from './dispositivos/dispositivos.component';
import { ModulosComponent } from './modulos/modulos.component';
import { NavbarComponent } from '../shared/navbar/navbar.component';

/*Interfaces*/
import { Dispositivo } from '../../interface/interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  @ViewChild(DispositivosComponent) dispositivosView: DispositivosComponent;
  @ViewChild(ModulosComponent) modulosView: ModulosComponent;
  @ViewChild(NavbarComponent) navbarView: NavbarComponent;

  data: boolean = true;
  dispositivo: Dispositivo;

  constructor() { }

  ngOnInit() {
    this.data = this.dispositivosView.data;
  }

  cargarSecciones(idx: string) {
    this.modulosView.cargarSecciones(idx);
    this.navbarView.setLugar(idx);
  }
}
